import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { SuspendDataPointTypeMutation, SuspendDataPointTypeMutationVariables } from '@/__generated__/types';

export const SUSPEND_DATAPOINT_TYPE = gql`
  mutation SuspendDataPointType($locationId: String!, $dataPointTypeId: String!) {
    suspendDataPointType(locationId: $locationId, dataPointTypeId: $dataPointTypeId) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<SuspendDataPointTypeMutation, SuspendDataPointTypeMutationVariables>

const useSuspendDataPointTypeMutation = (options?: TOptions) => useMutation<
  SuspendDataPointTypeMutation,
  SuspendDataPointTypeMutationVariables
>(SUSPEND_DATAPOINT_TYPE, options);

export default useSuspendDataPointTypeMutation;
