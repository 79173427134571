<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { MinusIcon, PlusIcon } from '@heroicons/vue/outline';
import { DataPointTypeValueUnitEnum } from '@/__generated__/types';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgPdfS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfS3FilePicker.vue';

defineProps<{
  label: string;
}>();

const value = defineModel<number | null>('value');
const file = defineModel<TPartialRepositoryFile | null>('file');
const isLoading = defineModel<boolean>('isLoading');

const { t } = useI18n();

const isFilePickerShown = ref(!!file.value);
</script>

<template>
  <AtInput
    v-model.number="value"
    type="number"
    labelClass="mb-3"
    wrapperClass="mb-1"
    :unit="DataPointTypeValueUnitEnum.EUR"
    :label="label"
    :placeholder="t('Enter value')"
  />
  <button
    type="button"
    class="flex text-gray-400 text-xs hover:text-primary items-center space-x-2"
    @click.prevent="isFilePickerShown = !isFilePickerShown"
  >
    <MinusIcon v-if="isFilePickerShown" class="h-5" />
    <PlusIcon v-else class="h-5" />
    <span>{{ t('Add document') }}</span>
  </button>
  <OgPdfS3FilePicker
    v-if="isFilePickerShown"
    v-model="file"
    v-model:uploading="isLoading"
    class="row-span-2 max-w-xs"
    :placeholder="t('Select or drop proof document')"
    showRecentFiles
    compact
  />
</template>
