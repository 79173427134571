<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { XIcon } from '@heroicons/vue/outline';
import { notify } from '@kyvg/vue3-notification';
import type { TButtons } from '@/components/organisms/types';
import {
  DataPointRequestStatusEnum,
  ReportingFrameworkEnum,
  type TmPendingDataPointRequestsQuery,
  type TmPendingDataPointRequestsQueryVariables,
  type TmPendingDataPointRequestsFiltersQuery,
  type TmPendingDataPointRequestsFiltersQueryVariables,
} from '@/__generated__/types';
import useApproveDataPointRequestMutation from '@/api/mutations/DataPointRequest/approveDataPointRequest.mutation';
import useApproveDataPointRequestsMutation from '@/api/mutations/DataPointRequest/approveDataPointRequests.mutation';
import useRejectDataPointRequestMutation from '@/api/mutations/DataPointRequest/rejectDataPointRequest.mutation';
import useRejectDataPointRequestsMutation from '@/api/mutations/DataPointRequest/rejectDataPointRequests.mutation';
import useUnapproveDataPointRequestMutation from '@/api/mutations/DataPointRequest/unapproveDataPointRequest.mutation';
import useUnapproveDataPointRequestsMutation
  from '@/api/mutations/DataPointRequest/unapproveDataPointRequests.mutation';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import { getUserName } from '@/utils/helpers/getUserName';
import grayBarsImg from '@/assets/grayBars.svg';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import MlDataPointRequestValue from '@/components/molecules/MlDataPointValue.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import MlStandardsBadges from '@/components/molecules/MlStandardsBadges.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import OgActionPanel from '@/components/organisms/OgActionPanel/OgActionPanel.vue';
import OgServerDataTable from '@/components/organisms/OgDataTable/OgServerDataTable.vue';
import type {
  FilterBy,
  SearchBy,
  ServerPaginationOptions,
  ServerSortOptions,
  TServerDataTableHeader,
  TDataTableItem,
} from '@/components/organisms/OgDataTable/types';
import PreviouslyApproved from '@/components/organisms/PreviouslyApproved.vue';
import { getFilterBy, getLimit, getSearchBy, getSortOrder } from '@/components/pages/PgApproval/utils';
import TM_PENDING_DATA_REQUEST_QUERY from './TmPendingDataPointRequests.query';
import TM_PENDING_DATA_REQUEST_FILTERS_QUERY from './TmPendingDataPointRequestsFilters.query';

type Props = {
  isLocationDisplayed: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isLocationDisplayed: false,
});

const { t } = useI18n();
const translateCategory = useCategoryTranslate();
const route = useRoute();
const router = useRouter();

const selectedStatusFilter = ref(DataPointRequestStatusEnum.PENDING);
const filtersQueryVariables = computed<TmPendingDataPointRequestsFiltersQueryVariables>(() => ({
  status: selectedStatusFilter.value,
}));
const { loading: filtersLoading, result: filtersResult } = useQuery<
  TmPendingDataPointRequestsFiltersQuery,
  TmPendingDataPointRequestsFiltersQueryVariables
>(
  TM_PENDING_DATA_REQUEST_FILTERS_QUERY,
  filtersQueryVariables,
  {
    fetchPolicy: 'network-only',
  },
);

const sortOptions = ref<ServerSortOptions>({
  sortBy: undefined,
  sortType: undefined,
});
const paginationOptions = ref<ServerPaginationOptions>({
  page: 1,
  rowsPerPage: 25,
});
const searchBy = ref<SearchBy>('');
const filterBy = ref<FilterBy>([{ field: '', criteria: [] }]);

const queryVariables = computed<TmPendingDataPointRequestsQueryVariables>(() => ({
  status: selectedStatusFilter.value,
  offset: (paginationOptions.value.page - 1) * paginationOptions.value.rowsPerPage,
  limit: getLimit(paginationOptions.value.rowsPerPage),
  sortBy: sortOptions.value.sortBy || '',
  sortOrder: getSortOrder(sortOptions.value.sortType),
  searchBy: getSearchBy(searchBy.value),
  filterBy: getFilterBy(filterBy.value),
}));

const { result, loading: queryLoading, refetch } = useQuery<
  TmPendingDataPointRequestsQuery,
  TmPendingDataPointRequestsQueryVariables
>(
  TM_PENDING_DATA_REQUEST_QUERY,
  queryVariables,
  {
    fetchPolicy: 'network-only',
  },
);

const isLoading = computed(() => queryLoading.value || filtersLoading.value);

const itemsSelected = ref<TDataTableItem[]>([]);

const dataPointRequests = computed(() => result.value?.getPaginatedApprovalDataPointRequests.items || []);

const selectedDataPointRequest = ref<typeof dataPointRequests.value[number] | null | undefined>(null);

onMounted(() => {
  if (route.meta?.approvalStatus && selectedStatusFilter.value !== route.meta.approvalStatus) {
    selectStatusFilter(route.meta.approvalStatus);
  }
});

watch(() => dataPointRequests.value, () => {
  selectedDataPointRequest.value = dataPointRequests.value.find((dpr) => dpr._id === route.params.dataPointRequest);
});

const reasonForRejection = ref('');
watch(selectedDataPointRequest, () => {
  reasonForRejection.value = selectedDataPointRequest.value?.rejectReason ?? '';
}, { immediate: true });

watch(selectedStatusFilter, () => {
  itemsSelected.value = [];
});

const { mutate: generateFileDownloadURLMutation } = useGenerateFileDownloadTokenMutation();

const {
  mutate: approveMutation, error: approveError, loading: approveLoading, called: approveCalled } = useApproveDataPointRequestMutation();
const {
  mutate: rejectMutation, error: rejectError, loading: rejectLoading, called: rejectCalled } = useRejectDataPointRequestMutation();
const {
  mutate: unapproveMutation,
  error: unapproveError, loading: unapproveLoading, called: unapproveCalled } = useUnapproveDataPointRequestMutation();
const {
  mutate: approveManyMutation,
  error: approveManyError, loading: approveManyLoading, called: approveManyCalled } = useApproveDataPointRequestsMutation();
const {
  mutate: rejectManyMutation,
  error: rejectManyError, loading: rejectManyLoading, called: rejectManyCalled } = useRejectDataPointRequestsMutation();
const {
  mutate: unapproveManyMutation,
  error: unapproveManyError, loading: unapproveManyLoading, called: unapproveManyCalled } = useUnapproveDataPointRequestsMutation();
type TMutation = typeof approveMutation | typeof rejectMutation | typeof unapproveMutation
type TMutationMany = typeof approveManyMutation | typeof rejectManyMutation | typeof unapproveManyMutation;
const resetErrors = () => [approveError, rejectError, unapproveError].forEach((e) => e.value === null);
const resetCalls = () => [approveCalled, rejectCalled, unapproveCalled, approveManyCalled, rejectManyCalled, unapproveManyCalled]
  .forEach((e) => { e.value = false; });

const withHandler = async (mutation: TMutation, dataPointRequestId: string) => {
  try {
    await mutation({
      approveDataPointRequestInput: { dataPointRequestId },
      rejectDataPointRequestInput: { dataPointRequestId, reason: reasonForRejection.value },
      unapproveDataPointRequestInput: { dataPointRequestId },
    });
    if (approveCalled.value) notify({ type: 'success', text: t('Datapoint was successfully approved') });
    if (rejectCalled.value) notify({ type: 'success', text: t('Datapoint was successfully rejected') });
    if (unapproveCalled.value) notify({ type: 'success', text: t('Datapoint was successfully unapproved') });
    resetCalls();
    selectedDataPointRequest.value = null;
    reasonForRejection.value = '';
  } catch (err) {
    if (approveError.value) notify({ type: 'error', text: t(approveError.value?.message) });
    if (rejectError.value) notify({ type: 'error', text: t(rejectError.value?.message) });
    if (unapproveError.value) notify({ type: 'error', text: t(unapproveError.value?.message) });
    setTimeout(resetErrors, 2000);
  }
  refetch();
};

const withHandlerForMultipleItems = async (mutation: TMutationMany, dataPointRequestIds: string[]) => {
  try {
    await mutation({
      approveDataPointRequestsInput: { dataPointRequestIds },
      rejectDataPointRequestsInput: { dataPointRequestIds },
      unapproveDataPointRequestsInput: { dataPointRequestIds },
    });
    if (approveManyCalled.value) notify({ type: 'success', text: t('Datapoints were successfully approved') });
    if (rejectManyCalled.value) notify({ type: 'success', text: t('Datapoints were successfully rejected') });
    if (unapproveManyCalled.value) notify({ type: 'success', text: t('Datapoints were successfully unapproved') });

    resetCalls();
    itemsSelected.value = [];
    reasonForRejection.value = '';
  } catch (err) {
    if (approveManyError.value) notify({ type: 'error', text: t(approveManyError.value?.message) });
    if (rejectManyError.value) notify({ type: 'error', text: t(rejectManyError.value?.message) });
    if (unapproveManyError.value) notify({ type: 'error', text: t(unapproveManyError.value?.message) });
  }
  refetch();
};

const downloadFileAction = async (fileId: string) => {
  const url = await generateFileDownloadURLMutation({
    repositoryFileId: fileId,
  });

  window.open(url?.data?.generateFileDownloadToken);
};

const selectStatusFilter = (filter: DataPointRequestStatusEnum) => {
  selectedStatusFilter.value = filter;
  selectedDataPointRequest.value = null;
};

const selectDataPoint = (dp: typeof selectedDataPointRequest.value) => {
  selectedDataPointRequest.value = dp;
  router.push({ name: route.name ?? '', params: { dataPointRequest: selectedDataPointRequest.value?._id } });
};

watch(route, () => {
  if (route.meta?.approvalStatus && selectedStatusFilter.value !== route.meta.approvalStatus) {
    selectStatusFilter(route.meta.approvalStatus);
  }
});

const titles = {
  [DataPointRequestStatusEnum.PENDING]: 'Open Approvals',
  [DataPointRequestStatusEnum.ACCEPTED]: 'Approved data',
  [DataPointRequestStatusEnum.REJECTED]: 'Rejected data',
};

const subtitles = {
  [DataPointRequestStatusEnum.PENDING]: 'Review and approve reported data from your team.',
  [DataPointRequestStatusEnum.ACCEPTED]: 'Review approved data.',
  [DataPointRequestStatusEnum.REJECTED]: 'Review rejected data.',
};

const shouldShowMultiSelectInTable = computed(() => selectedStatusFilter.value === DataPointRequestStatusEnum.PENDING
  || selectedStatusFilter.value === DataPointRequestStatusEnum.ACCEPTED);

const disabledButtons = computed(() => itemsSelected.value.length === 0);
const tableButtons = computed<TButtons<TDataTableItem[]> | undefined>(() => {
  const getIds = (data: TDataTableItem[]) => data.map((d) => d.dpr._id);
  if (selectedStatusFilter.value === DataPointRequestStatusEnum.PENDING) {
    return {
      primary: {
        text: t('Approve'),
        onClick: (data?: TDataTableItem[]) => {
          if (data) {
            withHandlerForMultipleItems(approveManyMutation, getIds(data));
          }
        },
        loading: approveManyLoading.value,
        disabled: disabledButtons.value,
      },
      secondary: {
        text: t('Request re-entry'),
        onClick: (data?: TDataTableItem[]) => {
          if (data) {
            withHandlerForMultipleItems(rejectManyMutation, getIds(data));
          }
        },
        loading: rejectManyLoading.value,
        disabled: disabledButtons.value,
      },
    };
  }
  if (selectedStatusFilter.value === DataPointRequestStatusEnum.ACCEPTED) {
    return {
      primary: {
        text: t('Undo approval'),
        onClick: (data?: TDataTableItem[]) => {
          if (data) {
            withHandlerForMultipleItems(unapproveManyMutation, getIds(data));
          }
        },
        loading: unapproveManyLoading.value,
        disabled: disabledButtons.value,
      },
      secondary: {
        text: t('Request re-entry'),
        onClick: (data?: TDataTableItem[]) => {
          if (data) {
            withHandlerForMultipleItems(rejectManyMutation, getIds(data));
          }
        },
        loading: rejectManyLoading.value,
        disabled: disabledButtons.value,
      },
    };
  }
  return undefined;
});

function updateItemsSelected(newItemsSelected: TDataTableItem[]) {
  itemsSelected.value = newItemsSelected;
}

const { formatNumber } = useFormatNumber();

const headers = computed<TServerDataTableHeader[]>(() => [
  {
    text: t('Datapoint'),
    value: 'friendlyName',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getApprovalDataPointRequestsFilters.dataPointTypes.reduce((acc, dataPointType) => ({
      ...acc,
      [dataPointType._id]: t(dataPointType.friendlyName),
    }), {}) || {},
  },
  {
    text: t('Value'),
    value: 'value',
    sortable: true,
  },
  {
    text: t('Category'),
    value: 'category',
    sortable: true,
    filterable: true,
    filterOptions: result.value?.getCategories.map((c) => c.slug)
      .filter((c) => filtersResult.value?.getApprovalDataPointRequestsFilters.categories.includes(c))
      .reduce((acc, category) => ({
        ...acc,
        [category]: translateCategory(category),
      }), {}) || {},
  },
  {
    text: t('Topic'),
    value: 'subcategory',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getApprovalDataPointRequestsFilters.subcategories.reduce((acc, subcategory) => ({
      ...acc,
      [subcategory.subcategory]: translateCategory(subcategory.category, subcategory.subcategory),
    }), {}) || {},
    hidden: false,
  },
  {
    text: t('Assignee'),
    value: 'assignee',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getApprovalDataPointRequestsFilters.assignees.reduce((acc, assignee) => ({
      ...acc,
      [assignee._id]: getUserName(assignee),
    }), {}) || {},
    hidden: false,
  },
  {
    text: t('Project'),
    value: 'location',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getApprovalDataPointRequestsFilters.locations.reduce((acc, location) => ({
      ...acc,
      [location._id]: location.name,
    }), {}) || {},
    hidden: !props.isLocationDisplayed,
  },
  {
    text: t('Period'),
    value: 'period',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getApprovalDataPointRequestsFilters.periods.reduce((acc, period) => ({
      ...acc,
      [JSON.stringify({ from: period.from, to: period.to })]: getDPRDateString(period.from, period.to),
    }), {}) || {},
  },
].filter(({ hidden }) => !hidden));

const items = computed(() => dataPointRequests.value.map((dpr) => ({
  dpr,
  friendlyName: t(dpr.dataPointType?.friendlyName ?? ''),
  value: formatNumber(dpr.value),
  assignee: dpr.delegations?.map((delegation) => delegation.user.email).join(', ') ?? getUserName(dpr.assignee),
  location: dpr.location?.name ?? t('No location assigned'),
  period: getDPRDateString(dpr.from, dpr.to),
  category: translateCategory(dpr.dataPointType.activeReportingFramework?.category ?? 'No category assigned'),
  subcategory: translateCategory(dpr.dataPointType.activeReportingFramework?.category, dpr.dataPointType.activeReportingFramework?.subcategory ?? 'No subcategory assigned'),
})));

const areFiltersEmpty = computed(() => filterBy.value.length === 0 || filterBy.value.every((item) => item.field.length === 0));
const showEmptyStateCard = computed(() => !isLoading.value && items.value.length === 0 && areFiltersEmpty.value);
</script>

<template>
  <div class="bg-white sm:rounded-md">
    <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
      {{ t(titles[selectedStatusFilter as keyof typeof titles]) }}
    </h1>
    <p class="mb-6 mt-3 text-sm text-gray-500">
      {{ t(subtitles[selectedStatusFilter as keyof typeof subtitles]) }}
    </p>

    <MlEmptyStateCard
      v-if="showEmptyStateCard"
      :title="t('No activities yet')"
      :description="t('Start by entering data and assigning to team members.')"
      :buttonText="t('Enter data')"
      @buttonClick="router.push({ name: 'projects' })"
    >
      <img
        class="mb-7"
        :src="grayBarsImg"
        alt="Gray Bars"
      >
    </MlEmptyStateCard>
    <OgServerDataTable
      v-else
      v-model:sortOptions="sortOptions"
      v-model:paginationOptions="paginationOptions"
      v-model:searchBy="searchBy"
      v-model:filterBy="filterBy"
      hover
      :itemsSelected="shouldShowMultiSelectInTable ? itemsSelected : undefined"
      :headers="headers"
      :items="items"
      :serverItemsLength="result?.getPaginatedApprovalDataPointRequests.totalCount || 0"
      :loading="isLoading"
      :controlsButtons="tableButtons"
      @clickRow="selectDataPoint($event.dpr)"
      @update:itemsSelected="shouldShowMultiSelectInTable ? updateItemsSelected($event) : undefined"
    >
      <template #item-value="{ dpr }">
        <MlDataPointRequestValue :dataPointValueAndType="dpr" />
      </template>
    </OgServerDataTable>

    <OgActionPanel
      v-if="selectedDataPointRequest"
      :isOpened="selectedDataPointRequest !== null"
      hasPaddingX
      :description="t(selectedDataPointRequest.dataPointType.question)"
      class="max-w-lg"
      @closePanel="selectedDataPointRequest = null"
    >
      <template #title>
        <div>
          {{ t(selectedDataPointRequest.dataPointType.friendlyName) }}

          <AtTooltipIcon
            v-if="selectedDataPointRequest.dataPointType.questionHelp"
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            class="cursor-pointer"
            @click.stop
          >
            <template #tooltip="{ hide }">
              <div class="flex flex-row-reverse whitespace-pre-line">
                <AtIconButton
                  class="h-6 w-6 shrink-0"
                  :icon="XIcon"
                  :title="t('Close')"
                  @click="hide"
                />
                <div>
                  <template
                    v-if="selectedDataPointRequest.dataPointType.reportingFrameworks
                      .find((reportingFramework) => reportingFramework.framework === ReportingFrameworkEnum.CUSTOM)"
                  >
                    <MlHtmlContent :html="selectedDataPointRequest.dataPointType.questionHelp" />
                  </template>
                  <template v-else>
                    {{ t(selectedDataPointRequest.dataPointType.questionHelp) }}
                  </template>
                </div>
              </div>
            </template>
          </AtTooltipIcon>
        </div>
        <MlStandardsBadges
          :activeReportingFramework="selectedDataPointRequest.dataPointType.activeReportingFramework"
          :reportingFrameworks="selectedDataPointRequest.dataPointType.reportingFrameworks"
        />
      </template>
      <div data-cy="MlPopup" class="text-sm">
        <h4 class="mb-2 mt-4">
          {{ t('Reported Data') }}
        </h4>

        <div class="divide-y divide-gray-200 overflow-x-hidden rounded bg-gray-50 px-4">
          <div class="grid w-full grid-cols-2 gap-2 py-4">
            <div>
              {{ t(selectedDataPointRequest.dataPointType.friendlyName) }}
            </div>
            <div>
              <MlDataPointRequestValue :dataPointValueAndType="selectedDataPointRequest" />
            </div>
          </div>
          <div
            v-if="selectedDataPointRequest.file"
            class="grid w-full grid-cols-2 gap-2 py-4"
          >
            <div>
              {{ t('Proof Document') }}
            </div>
            <div
              class=" cursor-pointer underline"
              @click="downloadFileAction(selectedDataPointRequest!.file!._id)"
            >
              {{ selectedDataPointRequest.file.filename }}
            </div>
          </div>

          <div class="grid w-full grid-cols-2 gap-2 py-4">
            <div>
              {{ t('Time Frame') }}
            </div>
            <div>
              <AtDataPointRequestDate
                :from="selectedDataPointRequest.from"
                :to="selectedDataPointRequest.to"
              />
            </div>
          </div>

          <div class="grid w-full grid-cols-2 gap-2 py-4">
            <div>
              {{ t('Due Date') }}
            </div>
            <div>
              <AtDataPointRequestDate :from="selectedDataPointRequest.dueDate" />
            </div>
          </div>

          <div class="grid w-full grid-cols-2 gap-2 py-4">
            <div>
              {{ t('Reported By') }}
            </div>
            <div
              v-if="!selectedDataPointRequest.assignee?.firstName && !selectedDataPointRequest.assignee?.lastName"
            >
              {{ selectedDataPointRequest.assignee?.email }}
            </div>
            <div v-else>
              {{ selectedDataPointRequest.assignee.firstName }}
              {{ selectedDataPointRequest.assignee.lastName }}
            </div>
          </div>
          <div class="grid w-full grid-cols-2 gap-2 py-4">
            <div>
              {{ t('Project') }}
            </div>
            <div>
              {{ selectedDataPointRequest.location.name }}
            </div>
          </div>
          <div
            v-if="selectedDataPointRequest.comment"
            class="grid w-full grid-cols-2 gap-2 py-4"
          >
            <div>
              {{ t('Comment') }}
            </div>
            <div>
              <MlHtmlContent
                class="line-clamp-4 hover:line-clamp-none"
                :html="selectedDataPointRequest.comment"
              />
            </div>
          </div>
        </div>

        <div class="mt-5">
          <MlTextarea
            v-model="reasonForRejection"
            :label="t('Reason for rejection')"
            :placeholder="t('Enter a comment')"
            :disabled="selectedDataPointRequest.status === DataPointRequestStatusEnum.REJECTED"
          />
        </div>

        <div
          v-if="selectedDataPointRequest.status === DataPointRequestStatusEnum.PENDING"
          class="mt-5 flex w-full justify-end"
        >
          <AtButton
            class="mr-4"
            variant="destructive"
            :loading="rejectLoading"
            :error="!!rejectError"
            @click="withHandler(rejectMutation, selectedDataPointRequest!._id)"
          >
            {{ t('Request Re-entry') }}
          </AtButton>
          <AtButton
            data-cy="approveDataPointRequest"
            :loading="approveLoading"
            :error="!!approveError"
            @click="withHandler(approveMutation, selectedDataPointRequest!._id)"
          >
            {{ t('Approve') }}
          </AtButton>
        </div>

        <div
          v-if="selectedDataPointRequest.status === DataPointRequestStatusEnum.ACCEPTED"
          class="mt-5 w-full flex justify-end"
        >
          <AtButton
            variant="destructive"
            data-cy="rejectDataPointRequest"
            class="mr-4"
            :loading="rejectLoading"
            :error="!!approveError"
            @click="withHandler(rejectMutation, selectedDataPointRequest!._id)"
          >
            {{ t('Request Re-entry') }}
          </AtButton>
          <AtButton
            variant="text"
            data-cy="unapproveDataPointRequest"
            :loading="unapproveLoading"
            :error="!!unapproveError"
            @click="withHandler(unapproveMutation, selectedDataPointRequest!._id)"
          >
            {{ t('Undo Approval') }}
          </AtButton>
        </div>

        <PreviouslyApproved
          :dataPointType="selectedDataPointRequest.dataPointType"
          :location="selectedDataPointRequest.location"
        />
      </div>
    </OgActionPanel>
  </div>
</template>
