import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { UnsuspendDataPointTypeMutation, UnsuspendDataPointTypeMutationVariables } from '@/__generated__/types';

export const REMOVE_SUSPENDED_DATAPOINT_TYPE = gql`
  mutation UnsuspendDataPointType($locationId: String!, $dataPointTypeId: String!) {
    unsuspendDataPointType(locationId: $locationId, dataPointTypeId: $dataPointTypeId) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<UnsuspendDataPointTypeMutation, UnsuspendDataPointTypeMutationVariables>

const useUnsuspendDataPointTypeMutation = (options?: TOptions) => useMutation<
  UnsuspendDataPointTypeMutation,
  UnsuspendDataPointTypeMutationVariables
>(REMOVE_SUSPENDED_DATAPOINT_TYPE, options);

export default useUnsuspendDataPointTypeMutation;
