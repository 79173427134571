<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyAssessmentQuery } from '@/__generated__/types';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity
  from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import MlRadio from '@/components/molecules/MlRadio.vue';

const { t } = useI18n();

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const router = useRouter();
const props = defineProps<TProps>();
const { formatNumber } = useFormatNumber();

const formData = reactive({
  totalCapex: -1,
  activityCapexs: {} as Record<string, number>,
});
const hasCapex = ref(false);

watch(() => props.assessment, () => {
  if (Object.keys(formData.activityCapexs).length === 0) {
    formData.activityCapexs = props.assessment.businessActivities
      .reduce((acc, curr) => ({ ...acc, [curr._id]: Math.max(0, curr.activityCapex) }), {});
  }

  if (formData.totalCapex === -1) {
    formData.totalCapex = Math.max(0, props.assessment.capex);
  }

  if (props.assessment.capex >= 0) {
    hasCapex.value = true;
  }
}, { immediate: true });

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

const taxonomyActivitiesCapexSum = computed(() => Object.values(formData.activityCapexs).reduce((acc, curr) => acc + curr, 0));
const taxonomyActivitiesCapexPercent = computed(() => (taxonomyActivitiesCapexSum.value / formData.totalCapex) * 100);
const activitiesNotIncludedSum = computed(() => formData.totalCapex - taxonomyActivitiesCapexSum.value);
const activitiesNotIncludedPercent = computed(() => 100 - taxonomyActivitiesCapexPercent.value);

const fields = ['Activity', 'Capex', 'Capex Proportion'];
const items = computed(() => [...props.assessment.businessActivities.map((activity) => ({
  '_id': activity._id, 'Activity': `${activity.activity.number}: ${t(activity.activity.name)}`, 'Capex': activity.activityCapex, 'Capex Proportion': ((formData.activityCapexs[activity._id] ?? 0) / Math.max(1, formData.totalCapex)) * 100,
})),
{
  'Activity': t('Activities eligible under the EU Taxonomy'),
  'Capex': taxonomyActivitiesCapexSum.value,
  'Capex Proportion': taxonomyActivitiesCapexPercent.value,
},
{
  'Activity': t('Activities not eligible under the EU Taxonomy'),
  'Capex': activitiesNotIncludedSum.value,
  'Capex Proportion': activitiesNotIncludedPercent.value,
},
{
  'Activity': t('TOTAL'),
  'Capex': (taxonomyActivitiesCapexSum.value + activitiesNotIncludedSum.value),
  'Capex Proportion': (activitiesNotIncludedPercent.value + taxonomyActivitiesCapexPercent.value),
},
]);

const isLoading = ref(false);
const radioOptions = [
  { name: 'yes', label: 'Yes', value: true },
  { name: 'no', label: 'No', value: false },
];

const next = async () => {
  isLoading.value = true;

  await updateTaxonomyAssessment({
    assessmentId: props.assessment._id,
    input: {
      capex: hasCapex.value && formData.totalCapex >= 0 ? formData.totalCapex : -1,
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_OPEX,
    },
  }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });

  for await (const [key, value] of Object.entries(formData.activityCapexs)) {
    await updateTaxonomyAssessmentBusinessActivity({
      assessmentId: props.assessment._id,
      businessActivityId: key,
      input: {
        activityCapex: hasCapex.value && value >= 0 ? value : -1,
      },
    }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });
  }

  router.push({ name: 'taxonomyAssessmentBusinessActivitiesOpex' });

  isLoading.value = false;
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentBusinessActivitiesTurnover' });
};

</script>

<template>
  <div class="mb-4">
    <p class="mb-2">
      {{ t('Do you want to assign CapEx to your activities?') }}
    </p>
    <MlRadio
      v-model="hasCapex"
      class="ml-6"
      :options="radioOptions"
    />
  </div>

  <div v-if="hasCapex">
    <AtInput v-model.number="formData.totalCapex" type="number" :label="t('What is the total amount of company’s capex?')" :placeholder="t('EUR')" unit="EUR" />

    <p class="my-4">
      {{ t('State your capex from each of the selected activities') }}
    </p>

    <OgSimpleTable
      :fields="fields"
      :items="items"
    >
      <template #item-activity="item">
        {{ t(item.Activity) }}
      </template>
      <template #item-capex="item">
        <AtInput v-if="item._id" v-model.number="formData.activityCapexs[item._id]" unit="EUR" type="number" />
        <template v-else>
          {{ formatNumber(item.Capex) }} EUR
        </template>
      </template>
      <template #item-capexProportion="item">
        <template v-if="item['Capex Proportion'] >= 0">
          {{ formatNumber(item['Capex Proportion']) }}%
        </template>
      </template>
    </OgSimpleTable>

    <p class="font-semibold">
      {{ t('Your total Taxonomy-eligible capex is {number} Euro.', { number: formatNumber(taxonomyActivitiesCapexSum) }) }}
    </p>
  </div>

  <div class="flex justify-between">
    <AtButton class="my-4 float-right" :disabled="isLoading" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton
      class="my-4 float-right"
      :loading="isLoading"
      :disabled="taxonomyActivitiesCapexPercent > 100 || formData.totalCapex === null"
      @click="next"
    >
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
