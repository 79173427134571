import { gql } from '@apollo/client/core';

export default gql`
  query OgCoreDataDialog {
    getTaxonomyCoreData {
      _id
      turnover
      turnoverFile {
        _id
        file {
          _id
          filename
          downloadUrl
        }
      }
      capEx
      capExFile {
        _id
        file {
          _id
          filename
          downloadUrl
        }
      }
      opEx
      opExFile {
        _id
        file {
          _id
          filename
          downloadUrl
        }
      }
      minimumSafeguards
    }
  }
`;
