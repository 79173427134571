<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useLazyQuery } from '@vue/apollo-composable';
import { computed, ref, watch, watchEffect } from 'vue';
import type { Dayjs } from '@/lib/dayjs/config';
import { type DataPointRequestStatusEnum, type MlCategoryDprsQuery, UserRole } from '@/__generated__/types';
import { formatDateRangeForBE } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import ML_CATEGORY_DPRS_QUERY from './MlCategoryDprs.query';
import MlTaskTitle from './MlTaskTitle.vue';

const router = useRouter();
const { currentUser } = useCurrentUser();

const props = defineProps<Props>();
type Props = {
  locationId: string
  category: string,
  isLocationToggled: boolean
  isCategoryToggled: boolean
  dateFrom: Dayjs
  dateTo: Dayjs
  status: DataPointRequestStatusEnum
}

const emit = defineEmits<Emits>();
type Emits = {
  loading: [locationId: string]
  toggleCategory: [category: string]
}
const limit = ref(10);
const dateRangeFormated = computed(() => formatDateRangeForBE(props.dateFrom, props.dateTo));
const queryVariables = computed(() => ({
  locationId: props.locationId,
  statusFilter: props.status,
  categoryFilter: [props.category],
  limit: limit.value,
  sortedByUpdate: true,
  assignedOnly: currentUser.value?.role !== UserRole.SUPERADMIN,
  includeChilds: true,
  ...dateRangeFormated.value,
}));

const { result, load, loading, fetchMore } = useLazyQuery<MlCategoryDprsQuery>(
  ML_CATEGORY_DPRS_QUERY,
  queryVariables,
  { fetchPolicy: 'network-only' },
);

watch(() => props.isLocationToggled, () => load());
watch(() => loading.value, () => emit('loading', props.locationId));

const hasMoreToFetch = ref(true);

const dprs = computed(() => result.value?.getMyDataPointRequests ?? []);

watch(() => loading.value, () => emit('loading', props.locationId));
watchEffect(() => {
  if (props.isLocationToggled) {
    load();
  }
});

const { t } = useI18n();
const translateCategory = useCategoryTranslate();

function goToDpr(category: string, subcategory: string, dptId: string, dprId:string) {
  router.push(`/projects/${
    props.locationId
  }/data-entry/?category=${
    category
  }&subcategory=${
    subcategory
  }&questiontype=${
    dptId
  }&questionid=${
    dprId
  }`);
}

function showMore() {
  limit.value += 10;
  fetchMore({
    variables: queryVariables.value,
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const newDprs = fetchMoreResult?.getMyDataPointRequests ?? [];
      const prevDprs = previousResult?.getMyDataPointRequests ?? [];
      if (newDprs.length <= prevDprs.length) {
        hasMoreToFetch.value = false;
      }
      return {
        getMyDataPointRequests: [...newDprs],
      };
    },
  });
}

function toggleCategory() {
  emit('toggleCategory', `${props.locationId}${props.status}${props.category}`);
}
</script>

<template>
  <div v-show="dprs.length">
    <MlTaskTitle
      :isOpen="props.isCategoryToggled"
      :title="translateCategory(props.category)"
      wrapperClass="mb-1"
      titleClass="text-gray-900 pl-1 pr-3 rounded-sm font-semibold text-sm hover:text-blue-600"
      @toggle="toggleCategory()"
    />
    <ul
      class="pl-4"
    >
      <TransitionGroup name="vertical-fade-slide">
        <li
          v-for="dpr in dprs"
          v-show="props.isCategoryToggled"
          :key="dpr._id"
          class="mb-3"
        >
          <div
            class="flex items-center cursor-pointer"
            @click.stop="goToDpr(
              dpr.dataPointTypeFlat.category,
              dpr.dataPointTypeFlat.subcategory,
              dpr.dataPointType._id,
              dpr._id,
            )"
          >
            <div class="w-1 h-1 bg-gray-900 rounded inline-block mx-2" />
            <div class="text-gray-900 hover:text-blue-600 hover:underline rounded-sm">
              {{ t(dpr.dataPointType.friendlyName) }}
              {{ dpr.isHistoric ? new Date(dpr.from).getFullYear() : '' }}
            </div>
          </div>
        </li>
      </TransitionGroup>
    </ul>

    <button
      v-if="hasMoreToFetch && isCategoryToggled && !(dprs.length < 10)"
      class="bg-gray-50 hover:bg-gray-200 rounded cursor-pointer text-center py-1 w-full"
      @click="showMore"
    >
      {{ t('Load more...') }}
    </button>
  </div>
</template>
