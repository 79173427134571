<script setup lang="ts">
import { getDPRDateString, getDPRDateTime } from '@/utils/helpers/dprDates';

type TProps = {
  from?: string | null,
  to?: string | null,
}
const props = defineProps<TProps>();
</script>

<template>
  <time :datetime="getDPRDateTime(props.from, props.to)">{{ getDPRDateString(props.from, props.to) }}</time>
</template>

