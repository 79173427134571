<script setup lang="ts">
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxItem,
  ComboboxItemIndicator,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport,
} from 'radix-vue';
import { SearchIcon } from '@heroicons/vue/outline';
import { PlusCircleIcon, XIcon, CheckIcon } from '@heroicons/vue/solid';
import { computed, ref, useTemplateRef, type ComponentPublicInstance } from 'vue';
import { useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';

const {
  placeholder = '',
  type = 'search',
  disabled = false,
  allowCreate = false,
} = defineProps<{
  placeholder?: string
  hideSearchIcon?: boolean
  disabled?: boolean
  type?: 'search' | 'select'
  allowCreate?: boolean
}>();

const { t } = useI18n();

const model = defineModel<string>({ required: true });
const options = defineModel<Record<string, string>>('options', { required: true });

const isOpen = ref(false);
const searchTerm = ref('');

const getKeyFormat = (text: string) => text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '_').replace(/^_+|_+$/g, '');

const canCreateOption = computed(() => searchTerm.value
  && !options.value[getKeyFormat(searchTerm.value)]
  && !options.value[searchTerm.value]
  && allowCreate,
);

function selectOption(option?: {key: string, name: string}) {
  if (option) {
    model.value = option.key;
    options.value[option.key] = option.name;
    isOpen.value = false;
  }
  if (!option && canCreateOption.value) {
    model.value = searchTerm.value;
    options.value[searchTerm.value] = searchTerm.value;
    isOpen.value = false;
  }
}

function deselectOption() {
  model.value = '';
  delete options.value[''];
}

const comboboxRootRef = useTemplateRef<ComponentPublicInstance>('comboboxRootRef');
onClickOutside(comboboxRootRef, () => {
  const optionKey = Object.keys(options.value).find(
    (key) => [getKeyFormat(searchTerm.value), searchTerm.value].includes(options.value[key]));
  if (!optionKey) {
    searchTerm.value = '';
  }
});

</script>

<template>
  <ComboboxRoot
    ref="comboboxRootRef"
    v-model:searchTerm="searchTerm"
    v-model:open="isOpen"
    v-model="model"
    class="relative text-sm"
    :class="{ 'text-gray-200': disabled }"
    :disabled="disabled"
    :displayValue="(value) => options[value]"
    :resetSearchTermOnBlur="false"
    @keyup.enter.prevent.stop="selectOption()"
  >
    <ComboboxAnchor
      class="min-w-[160px] w-full inline-flex p-2 justify-between bg-white rounded-md border border-gray-400 outline-none"
    >
      <ComboboxTrigger class="flex w-[80%]">
        <SearchIcon
          v-if="type === 'search' && !hideSearchIcon"
          class="w-5 mr-1 shrink-0"
        />
        <ComboboxInput
          class="!bg-transparent outline-none h-full placeholder:text-gray-400 w-full"
          :placeholder="placeholder"
        />
      </ComboboxTrigger>
      <PlusCircleIcon
        v-show="canCreateOption"
        class="w-5 ml-auto text-primary cursor-pointer shrink-0"
        :title="t('Create')"
        @click.stop.prevent="selectOption()"
      />
      <button
        type="button"
        :disabled="disabled"
        @click.stop.prevent="deselectOption"
      >
        <XIcon
          v-show="!canCreateOption && model"
          class="ml-auto w-4 text-gray-400 shrink-0"
          :class="{
            'hover:text-primary': !disabled,
          }"
        />
      </button>
    </ComboboxAnchor>

    <ComboboxContent
      class="absolute z-10 w-full mt-1 min-w-[160px] max-h-[160px] bg-white border-[0.5px] border-primary overflow-hidden rounded shadow-lg will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
    >
      <ComboboxViewport class="p-[5px]">
        <ComboboxEmpty class="text-gray-400 text-xs font-medium text-center">
          <span
            v-if="canCreateOption"
            class="text-gray-900 hover:text-white hover:bg-primary cursor-pointer px-2 py-1 flex rounded"
            @click.stop.prevent="selectOption()"
          >
            {{ t('Create option: {optionName}', { optionName: searchTerm }) }}
          </span>
          <span v-else>
            {{ t('No options') }}
          </span>
        </ComboboxEmpty>
        <ComboboxItem
          v-for="(option, key) in options"
          :key="key"
          class="text-xs leading-none rounded-md flex items-center min-h-[25px] py-1 px-2 relative select-none data-[disabled]:text-gray-400 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-primary data-[highlighted]:text-white"
          :value="option"
          @select.prevent="selectOption({ key, name: option })"
        >
          <span>{{ option }}</span>
          <ComboboxItemIndicator class="w-[25px] ml-auto inline-flex items-center justify-center">
            <CheckIcon class="w-4 text-primary" />
          </ComboboxItemIndicator>
        </ComboboxItem>
      </ComboboxViewport>
    </ComboboxContent>
  </ComboboxRoot>
</template>
