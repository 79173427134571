import { gql } from '@apollo/client/core';

export default gql`
  query TmSettingsTeam($allTeamsUsers: Boolean!) {
    getTeamUsers (includeInactive: true) {
      _id
      ...userFields
    }

    getAllTeamsUsers @include(if: $allTeamsUsers) {
      _id
      ...userFields
    }
  }
  
  fragment userFields on User {
    _id
    firstName
    lastName
    jobTitle
    jobDepartment
    email
    role
    language
    onboardingStatus
    getStartedStepsCompleted

    entities {
      _id
      name
    }

    invitedBy {
      _id
    }

    picture {
      _id
      downloadUrl
    }

    assignments {
      _id
      categories {
        category
        role
        location {
          _id
          name
        }
      }
      subcategories {
        subcategory
        role
        location {
          _id
          name
        }
      }
      datapointtypes {
        dataPointType {
          _id
          name
        }
        role
        location {
          _id
          name
        }
      }
      doubleMateriality
    }
  }
`;
