import { gql } from '@apollo/client/core';

export default gql`
  query PgHome {
    entityLocationSummaries {
      _id
      name
    }
    
    getTeamUsers {
      _id
      firstName
      lastName
      role
      email

      assignments {
        _id
        categories {
          category
          role
          location {
            _id
            name
          }
        }
        subcategories {
          subcategory
          role
          location {
            _id
            name
          }
        }
        datapointtypes {
          dataPointType {
            _id
            name
          }
          role
          location {
            _id
            name
          }
        }
      }
    }

    getOwnUser {
      _id
      entity {
        _id
        locations {
          _id
          name
        }
      }
    }
  }
`;
