<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import EasyDataTable, { type ServerOptions } from 'vue3-easy-data-table';
import type { TButtons } from '@/components/organisms/types';
import { colors } from '@/styles/theme';
import type {
  FilterBy,
  SearchBy,
  ServerPaginationOptions,
  ServerSortOptions,
  TServerDataTableHeader,
  TDataTableItem,
} from './types';
import OgServerDataTableControls from './OgServerDataTableControls.vue';

type Props = {
  headers: TServerDataTableHeader[];
  items: TDataTableItem[];
  searchBy?: SearchBy;
  filterBy: FilterBy;
  sortOptions: ServerSortOptions;
  paginationOptions: ServerPaginationOptions;
  wrapperClass?: string;
  secondStyle?: boolean;
  hasTotal?: boolean;
  itemsSelected?: TDataTableItem[];
  hover?: boolean;
  controlsHidden?: boolean;
  controlsButtons?: TButtons<TDataTableItem[]>;
};

const props = withDefaults(defineProps<Props>(), {
  wrapperClass: '',
  searchBy: '',
  secondStyle: false,
  hasTotal: false,
  itemsSelected: undefined,
  hover: false,
  controlsHidden: false,
  controlsButtons: undefined,
});

const emit = defineEmits<{
  'update:itemsSelected': [value: TDataTableItem[]];
  'update:sortOptions': [value: ServerSortOptions];
  'update:paginationOptions': [value: ServerPaginationOptions];
  'update:searchBy': [value: SearchBy];
  'update:filterBy': [value: FilterBy];
}>();

defineOptions({ inheritAttrs: false });

const { t } = useI18n();

const serverOptions = computed<ServerOptions>(() => ({
  page: props.paginationOptions.page,
  rowsPerPage: props.paginationOptions.rowsPerPage,
  sortBy: props.sortOptions.sortBy,
  sortType: props.sortOptions.sortType,
}));

function getHeaderClasses() {
  return props.secondStyle ? 'text-left' : '';
}

function handleServerOptionsChange(newServerOptions: ServerOptions) {
  emit('update:paginationOptions', ({
    page: newServerOptions.page,
    rowsPerPage: newServerOptions.rowsPerPage,
  }));
}
</script>

<template>
  <div
    class="py-6"
    :class="wrapperClass"
  >
    <OgServerDataTableControls
      v-if="!controlsHidden"
      :sortOptions="sortOptions"
      :searchBy="searchBy"
      :filterBy="filterBy"
      :headers="headers"
      :itemsSelected="itemsSelected"
      :buttons="controlsButtons"
      @update:sortOptions="$emit('update:sortOptions', $event)"
      @update:searchBy="$emit('update:searchBy', $event)"
      @update:filterBy="$emit('update:filterBy', $event)"
    />

    <EasyDataTable
      :itemsSelected="itemsSelected"
      :serverOptions="serverOptions"
      class="og-data-table"
      :class="[
        { 'og-data-table--style-1': !secondStyle },
        { 'og-data-table--style-2': secondStyle },
        { 'og-data-table--has-total': hasTotal },
      ]"
      :headerItemClassName="getHeaderClasses"
      :headers="headers"
      :items="items"
      :themeColor="colors.primary.DEFAULT"
      :bodyRowClassName="hover ? 'cursor-pointer' : ''"
      :noHover="!hover"
      :hideFooter="controlsHidden"
      :rowsPerPageMessage="t('rows per page:')"
      :fixedHeader="false"
      :rowsItems="[25, 50, 100, 200]"
      :rowsPerPage="25"
      buttonsPagination
      data-cy="serverDataTable"
      v-bind="$attrs"
      @update:itemsSelected="$emit('update:itemsSelected', $event)"
      @update:serverOptions="handleServerOptionsChange"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </EasyDataTable>
  </div>
</template>

<style lang="postcss" scoped>
.og-data-table {

  --easy-table-row-border: 1px solid theme(colors.gray.200);
  --easy-table-header-font-size: theme(fontSize.sm);
  --easy-table-header-height: theme(spacing.14);
  --easy-table-body-row-height: theme(spacing.16);

  --easy-table-footer-font-size: theme(fontSize.sm);
  --easy-table-footer-height: theme(spacing.20);
  --easy-table-rows-per-page-selector-width: theme(spacing.14);
  --easy-table-rows-per-page-selector-option-padding: theme(spacing.2);
}
.og-data-table--style-1 {
  @apply border-x-0 border-b-0 mt-4;

  --easy-table-header-font-color: theme(colors.gray.400);
  --easy-table-body-row-font-size: theme(fontSize.sm);

}

.og-data-table--style-2 {
  @apply border-0 mt-4;

  --easy-table-header-background-color: theme(colors.gray.200);
  --easy-table-header-height: theme(spacing.12);
  --easy-table-body-row-height: theme(spacing.12);
  --easy-table-header-font-color: theme(colors.gray.400);

  /* Prevent vue3-easy-data-table bug (css variable background-color incorrectly overflows on borders)*/
  --easy-table-body-row-background-color: var(--easy-table-body-row-background-color, unset);
}

.og-data-table--style-2:deep(.vue3-easy-data-table__header tr th) {
  @apply border-b-0
}
.og-data-table--style-2:deep(.vue3-easy-data-table__header tr th:first-child) {
  @apply rounded-l-md;
}
.og-data-table--style-2:deep(.vue3-easy-data-table__header tr th:last-child) {
  @apply rounded-r-md;
}

.og-data-table--style-2:deep(.vue3-easy-data-table__body tr td) {
  @apply py-2
}

.og-data-table:deep(.vue3-easy-data-table__main) {
  @apply overflow-visible;
}

.og-data-table:deep(th:first-child),
.og-data-table:deep(td:first-child) {
  @apply pl-4;
}

.og-data-table:deep(td) {
  @apply py-2;
}

.og-data-table:deep(.select-items li) {
  @apply hover:bg-gray-200;
}

.og-data-table:deep(.pagination__rows-per-page) {
  @apply capitalize;
}

.og-data-table:deep(.vue3-easy-data-table__message) {
  @apply text-sm;
}

.og-data-table:deep(.sortable) {
  @apply pointer-events-none;
}

.og-data-table:deep(th.sortable .sortType-icon) {
  @apply hidden;
}

.og-data-table:deep(th) {
  @apply uppercase;
}

.og-data-table--has-total:deep(tr:last-child > td)  {
  @apply font-semibold !border-b-0 border-double border-r-0 border-l-0
}
</style>
