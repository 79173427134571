<script setup lang="ts">
import type { Component } from 'vue';

const props = defineProps<{
  icon: Component,
  title: string,
}>();

</script>

<template>
  <button
    type="submit"
    class="inline-flex h-6 rounded-md px-1 transition-colors hover:bg-gray-200 hover:text-primary disabled:pointer-events-none disabled:opacity-50"
    :title="props.title"
  >
    <component
      :is="props.icon"
      class="block h-full w-full"
    />
    <slot />
  </button>
</template>

