<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { CloudDownloadIcon } from '@heroicons/vue/outline';
import MlModal from '@/components/molecules/MlModal.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { ModalState, ReportType } from './types';

const { t } = useI18n();
interface TProps {
  isRevealed: boolean,
  title?: string;
  formatSelected?: 'special' | 'standard';
  description?: string;
  modalKey: ReportType;
  modalState: ModalState;
}

const props = withDefaults(defineProps<TProps>(), {
  title: '',
  description: '',
  formatSelected: 'standard',
});
const emit = defineEmits(['update:isRevealed', 'closeModal', 'requestEsgDNKReport', 'requestCustomReport', 'next']);

const currentModalContent = computed(
  () => modalContent.value
    .filter((type) => type.key === props.modalKey)
    .map((_type) => _type[props.modalState])[0],
);

watch(() => props.formatSelected, () => {
  const index = modalContent.value.findIndex((reportType) => reportType.key === ReportType.CUSTOM);
  if (modalContent.value[index].request) {
    modalContent.value[index].request.buttonIcon = props.formatSelected === 'standard' ? CloudDownloadIcon : undefined;
    modalContent.value[index].request.buttonText = props.formatSelected === 'standard' ? t('Download report') : t('Request report');
  }
});

const modalContent = ref([
  {
    key: ReportType.CO2,
    noData: {
      title: t('Looks like there’s no data yet'),
      description: t('The date range you chose contains no data. Change the date range or or it is possible that there is insufficient Emission data. Start submitting Emissions data points with your team, and then request a report.'),
      hasCancelButton: false,
      buttonText: t('Got it'),
      action: () => emit('closeModal'),
    },
  },
  {
    key: ReportType.ESG,
    request: {
      title: t('Request ESG report'),
      description: t('Request your ESG report from the Codio Impact team here and will come back to you via email as soon as possible, depending on the complexity of the projects.'),
      hasCancelButton: true,
      buttonText: t('Request Report'),
      action: () => emit('requestEsgDNKReport'),
    },
    success: {
      title: t('Thank you for requesting an ESG report'),
      description: t('We have received your request for the ESG report and will come back to you via email as soon as possible, depending on the complexity of the projects.'),
      hasCancelButton: false,
      buttonText: t('Close'),
      action: () => emit('closeModal'),
    },
    noData: {
      title: t('Looks like there’s no data yet'),
      description: t('Requesting an ESG report is not possible for now. When you start entering and submitting data points with your team, you’ll be able to request an ESG report.'),
      hasCancelButton: false,
      buttonText: t('Got it'),
      action: () => emit('closeModal'),
    },
  },
  {
    key: ReportType.CUSTOM,
    select: {
      title: t('Request custom questionnaires report'),
      hasCancelButton: true,
      description: '',
      buttonIcon: undefined,
      buttonText: t('Next'),
      action: () => emit('next'),
    },
    request: {
      title: t('Request custom questionnaires report'),
      hasCancelButton: true,
      description: '',
      buttonIcon: CloudDownloadIcon,
      buttonText: t('Download report'),
      action: () => emit('requestCustomReport'),
    },
    success: {
      title: t('Success!'),
      hasCancelButton: false,
      description: t('You requested the custom questionnaire export. You will receive your report within 1-2 working days.'),
      buttonIcon: undefined,
      buttonText: t('Finish and close'),
      action: () => emit('closeModal'),
    },
    noData: {
      title: t('Looks like there’s no data yet'),
      description: t('Requesting an ESG report is not possible for now. When you start entering and submitting data points with your team, you’ll be able to request an ESG report.'),
      hasCancelButton: false,
      buttonIcon: undefined,
      buttonText: t('Got it'),
      action: () => emit('closeModal'),
    },
  },
]);

</script>

<template>
  <MlModal
    :isRevealed="props.isRevealed"
    :class="{ 'bg-blue-50': props.modalState === ModalState.SUCCESS }"
    @close="emit('closeModal')"
    @update:isRevealed="emit('update:isRevealed', $event)"
  >
    <h3 class="font-bold text-lg">
      {{ currentModalContent?.title }}
    </h3>
    <slot />
    <div v-if="currentModalContent?.description" class="mt-2">
      <p class="text-sm text-gray-500">
        {{ t(currentModalContent.description) }}
      </p>
    </div>
    <div class="mt-4 flex justify-between">
      <AtButton
        v-if="currentModalContent?.hasCancelButton"
        class="mr-2"
        variant="outline"
        data-cy="approveDataPointRequest"
        @click.stop="emit('closeModal')"
      >
        {{ t('Cancel') }}
      </AtButton>
      <AtButton
        class="ml-auto"
        data-cy="approveDataPointRequest"
        :icon="currentModalContent?.buttonIcon"
        @click.stop="currentModalContent?.action()"
      >
        {{ currentModalContent?.buttonText }}
      </AtButton>
    </div>
  </MlModal>
</template>
