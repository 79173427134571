<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { XIcon } from '@heroicons/vue/outline';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyAssessmentQuery, type RepositoryFile } from '@/__generated__/types';
import OgPdfS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfS3FilePicker.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import { router } from '@/router';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const props = defineProps<TProps>();
const { t } = useI18n();
const route = useRoute();
const { mutate, loading: isLoading } = useUpdateTaxonomyAssessment();

const files = ref<{ _id: string | null, file: { _id: string, filename: string } }[]>([]);

const isUploading = ref(false);

watch(() => props.assessment, () => {
  files.value = [...props.assessment.documents];
}, { immediate: true });

const handleFileUploaded = (event?: Pick<RepositoryFile, '_id' | 'filename'> | null) => {
  if (event) {
    files.value.push({ _id: null, file: event });
  }
};

const next = async (withDocumentUpdate: boolean) => {
  await mutate({
    assessmentId: props.assessment._id,
    input: {
      ...(withDocumentUpdate ? { documents: files.value.map((file) => ({
        _id: file._id,
        fileId: file.file._id,
      })) } : {}),
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
    },
  }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });

  router.push({ name: 'taxonomyResults', params: { projectId: route.params.projectId } });
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentMinimumSafeguardsChecklist' });
};
</script>

<template>
  <div>
    <p class="font-semibold mb-4">
      {{ t('Please upload all relevant documentation on this project’s assessment.') }}
    </p>

    <span class="font-semibold text-sm">
      {{ t("Documents") }}
    </span>
    <OgPdfS3FilePicker
      class="mt-1"
      :uploading="isUploading"
      :placeholder="t('Select or drop documents')"
      :description="t('{fileTypes} up to {maxSizeInMB} MB.', { fileTypes: 'PDF', maxSizeInMB: 10 })"
      @update:modelValue="handleFileUploaded($event)"
    />

    <div class="my-5">
      <p class="font-bold text-sm my-2">
        {{ t('File name') }}
      </p>

      <div
        v-for="([idx, file], index) in Object.entries(files)"
        :key="index"
      >
        <div class="flex justify-between max-w-sm">
          <p>{{ file.file.filename }}</p>
          <AtIconButton title="Delete" :icon="XIcon" class="text-gray-400" @click="files.splice(Number(idx), 1)" />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-between">
    <div>
      <AtButton class="my-4 float-right" :disabled="isUploading" variant="outline" @click="prev">
        {{ t('Back') }}
      </AtButton>
    </div>

    <div>
      <AtButton class="m-4" :disabled="isLoading || isUploading" variant="outline" @click="next(false)">
        {{ t('Skip') }}
      </AtButton>
      <AtButton class="m-4 mr-0" :loading="isUploading || isLoading" @click="next(true)">
        {{ t('Continue') }}
      </AtButton>
    </div>
  </div>
</template>
