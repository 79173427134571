<script setup lang="ts">
import groupBy from 'lodash/groupBy';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { computed, inject, watch, ref, type ComputedRef } from 'vue';
import uniqBy from 'lodash/uniqBy';
import type { ChartOptions } from 'chart.js';
import { ApolloError } from '@apollo/client';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';
import TM_ADMIN_DASHBOARD_QUERY from '@/components/pages/PgAdmin/Home/Dashboard/Dashboard.query';
import {
  type TmDashboardQuery,
  type TmDashboardChartSelectionQuery,
  type TmDashboardStatisticsQuery,
  type TmDashboardStatisticsQueryVariables,
  DataPointTypeEmissionTypeEnum,
} from '@/__generated__/types';
import Statistics from '@/components/pages/PgAdmin/Home/Dashboard/Statistics.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import DashboardShareModal from '@/components/pages/PgAdmin/Home/Dashboard/DashboardShareModal.vue';
import TmWorkspacePerformance from '@/components/pages/PgAdmin/Home/Dashboard/TmWorkspacePerformance.vue';
import logoSquare from '@/assets/logo-square.svg';
import { router } from '@/router';
import { chartColors, colors } from '@/styles/theme';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import { useReportingPeriod } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtAddButtonVue from '@/components/atoms/AtAddButton.vue';
import useChartProps from '@/components/organisms/OgChart/useChartProps';
import type { TDataPointItem } from '@/components/pages/PgStatistics/PgResults/types';
import MlModal from '@/components/molecules/MlModal.vue';
import OgDataPointTypeSelect from '@/components/organisms/OgDataPointTypeSelect/OgDataPointTypeSelect.vue';
import { normalizeDatapointsByType } from '@/utils/helpers/datapoints/normalizeDatapoints';
import { groupDataPointsByProject } from '@/utils/helpers/datapoints/groupDatapointsByProject';
import { calculateHeaders } from '@/utils/helpers/datapoints/tableHeaders';
import { normalizeChartData } from '@/utils/helpers/charts/normalizeChartData';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import TM_ADMIN_DASHBOARD_STATISTICS_QUERY from '@/components/pages/PgAdmin/Home/Dashboard/Statistics.query';
import TM_DASHBOARD_CHART_SELECTION_QUERY from './Dashboard/DashboardCharts.query';

const { t } = useI18n();

const adminMode = inject('adminMode', computed(() => false));
const isExternal = inject('isExternal', computed(() => false));
const isModalRevealed = ref(false);
const selectedDataPointTypeName = ref('');

const queryString = new URLSearchParams(window.location.search);
const { result: statisticsResult } = useQuery<
TmDashboardStatisticsQuery,
 TmDashboardStatisticsQueryVariables>(TM_ADMIN_DASHBOARD_STATISTICS_QUERY, {
   isExternal: isExternal.value,
   adminMode: adminMode.value || isExternal.value,
   ...(isExternal.value ? {
     shareToken: queryString.get('shareToken') ?? '',
     sharePassword: window.localStorage.getItem('sharePassword') ?? '',
     entityId: queryString.get('entity') ?? '',
   } : {}),

 });

const dto = computed(() => ({
  numberOfEntities:
   (isExternal.value ? statisticsResult.value?.getNumberOfEntitiesExternal : statisticsResult.value?.getNumberOfEntities) ?? 0,
  numberOfLocations:
   (isExternal.value ? statisticsResult.value?.getNumberOfLocationsExternal : statisticsResult.value?.getNumberOfLocations) ?? 0,
  numberOfUsers:
   (isExternal.value ? statisticsResult.value?.getNumberOfUsersExternal : statisticsResult.value?.getNumberOfUsers) ?? 0,
  numberOfGoals:
   (isExternal.value ? statisticsResult.value?.getNumberOfEntityKpisExternal : statisticsResult.value?.getNumberOfEntityKpis) ?? 0,
}));

const statisticsItems = computed(() => [
  { label: 'Workspaces', number: dto.value.numberOfEntities, color: 'bg-blue-50' },
  { label: 'Projects', number: dto.value.numberOfLocations, color: 'bg-orange-50' },
  { label: 'Users', number: dto.value.numberOfUsers, color: 'bg-rose-50' },
  { label: 'Goals', number: dto.value.numberOfGoals, color: 'bg-teal-50' },
]);

const { dateRange, formatForBE } = useReportingPeriod();

const { result, error, refetch } = useQuery<TmDashboardQuery>(TM_ADMIN_DASHBOARD_QUERY, {
  isExternal: isExternal.value,
  adminMode: adminMode.value || isExternal.value,
  ...(isExternal.value ? {
    shareToken: queryString.get('shareToken') ?? '',
    sharePassword: window.localStorage.getItem('sharePassword') ?? '',
    entityId: queryString.get('entity') ?? '',
  } : {}),
  ...formatForBE(),
});

const esgDataPointsLength = computed(() => tmChartSelection.value?.getEsgDataPointsLength);
const items: ComputedRef<TDataPointItem[]> = computed(
  () => normalizeDatapointsByType({
    chartSelectionDataPoints: chartSelectionDataPoints.value,
    isAdmin: adminMode.value,
  }),
);

const {
  result: tmChartSelection,
  loading: tmChartSelectionLoading } = useQuery<TmDashboardChartSelectionQuery>(TM_DASHBOARD_CHART_SELECTION_QUERY, () => ({
    dataPointTypeNames: [selectedDataPointTypeName.value ?? ''],
    allEntities: true,
    type: 'dashboard',
  }), { fetchPolicy: 'network-only' },
  );

const kpis = computed(() => tmChartSelection.value?.getCurrentUserEntities.flatMap((e) => e.kpis) ?? []);
const chartSelectionDataPoints = computed(() => tmChartSelection.value?.getAdminDataPointsByType ?? []);
const { chartDateRange, getChartProps } = useChartProps({ adminType: 'dashboard' });
const pinnedDataPointTypes = computed(() => uniqBy(tmChartSelection.value?.getCurrentUserEntities
  .flatMap((e) => e.entitySettings.dashboardPinnedDataPointTypes ?? []) ?? [], 'dataPointType.name'));

const chartData = computed(() => {
  const { selectedDataPointType, kpisOfDataPointType } = normalizeChartData({
    availableDataPointsGroupedByType: result.value?.getAvailableDataPointsGroupedByType ?? [],
    selectedDataPointTypeName: selectedDataPointTypeName.value,
    kpis: kpis.value,
  });

  return getChartProps(chartSelectionDataPoints.value, selectedDataPointType, kpisOfDataPointType);
});

const selectedWorkspace = ref<string[]>([]);

const charts = computed(() => pinnedDataPointTypes.value.map((pinnedDataPointType) => {
  const dataPoints = (tmChartSelection.value?.getPinnedDataPointsAdmin?.filter(
    ({ dataPointType: { name } }) => {
      const isBelongToChartDataPointType = name === pinnedDataPointType.dataPointType.name;
      return isBelongToChartDataPointType;
    }) ?? []).map((d) => ({
    ...d,
    location: {
      _id: d.entity?._id,
      name: d.entity?.name ?? '',
    },
  })).filter(({ location }) => !selectedWorkspace.value.length || selectedWorkspace.value.includes(String(location.name)));

  const kpisOfDataPointType = kpis.value.filter((kpi) => kpi.dataPointType._id === pinnedDataPointType.dataPointType._id);

  return getChartProps(dataPoints, pinnedDataPointType.dataPointType, kpisOfDataPointType);
}));

watch(error, () => {
  if (error.value instanceof ApolloError) {
    if (error.value.message === 'Forbidden') {
      router.push(`/external/login${window.location.search}`);
    }
  }
});

const emissions = computed(() => (isExternal.value ? result.value?.getEmissionsExternal : result.value?.getEmissions) ?? []);
const reportingProgress = computed(() => (isExternal.value
  ? result.value?.getReportingProgressExternal : result.value?.getReportingProgress
) ?? [] as NonNullable<TmDashboardQuery['getReportingProgress']>,
);

const reportingData = computed(() => [
  { status: t('Open'), value: reportingProgress.value.reduce((curr, acc) => curr + acc.open, 0), color: chartColors[0][1] },
  { status: t('Pending'), value: reportingProgress.value.reduce((curr, acc) => curr + acc.pending, 0), color: chartColors[1][1] },
  { status: t('Rejected'), value: reportingProgress.value.reduce((curr, acc) => curr + acc.rejected, 0), color: chartColors[2][1] },
  { status: t('Accepted'), value: reportingProgress.value.reduce((curr, acc) => curr + acc.accepted, 0), color: chartColors[3][1] },
  { status: t('Overdue'), value: reportingProgress.value.reduce((curr, acc) => curr + acc.overDue, 0), color: colors.violet['500'] },
]);
const totalQuestionsCount = computed(() => reportingData.value.reduce((acc, curr) => curr.value + acc, 0));

const reportingProgressData = computed(() => ({
  datasets: [{
    data: reportingData.value.map((d) => d.value),
    backgroundColor: reportingData.value.map((d) => d.color),
  }],
  labels: reportingData.value.map((d) => `${d.status}: ${
    (totalQuestionsCount.value ? ((d.value / totalQuestionsCount.value) * 100) : 0).toFixed()
  }%`),
}));

const chartOptions = computed(() => ({
  plugins: {
    doughnutlabel: {
      labels: [
        { text: totalQuestionsCount.value, font: { size: 60 } },
        { text: t('questions'), font: { size: 30 } },
      ],
    },
  },
}));

const emissionChartData = computed(() => {
  const shouldRenderScope3 = emissions.value.some((e) => e.scope === 'SCOPE_3');
  const backgroundColor = [chartColors[0][1], chartColors[0][1]];
  const scopes = shouldRenderScope3 ? [1, 2, 3] : [1, 2];
  const labels = scopes.map((scope) => `Scope ${scope}`);
  const data = scopes.map((scope) => emissions.value.reduce((acc, curr) => acc + (curr.scope === `SCOPE_${scope}` ? curr.value : 0), 0));

  const dataSet = {
    datasets: [{
      data,
      backgroundColor,
    }],
    labels,
  };

  return dataSet;
});

const emissionsChartOptions: ChartOptions = {
  indexAxis: 'y',
  plugins: {
    title: {
      display: true,
      text: 'tCO2e',
      position: 'bottom',
    },
  },
};

const scopesChartData = computed(() => {
  const groups = groupBy(emissions.value, (emission) => emission.scope);
  return [
    { data: groups[DataPointTypeEmissionTypeEnum.SCOPE_1] || [], label: t('Scope 1 Emissions') },
    { data: groups[DataPointTypeEmissionTypeEnum.SCOPE_2] || [], label: t('Scope 2 Emissions') },
    { data: groups[DataPointTypeEmissionTypeEnum.SCOPE_3] || [], label: t('Scope 3 Emissions') },
  ]
    .filter(({ data }) => data.length > 0)
    .map(({ data, label }) => ({
      label,
      chartData: {
        datasets: [{
          data: data.map((emission) => emission.value),
          backgroundColor: data.map((_, index) => chartColors[index][1]),
        }],
        labels: data.map((emission) => `${t(emission.category)}: ${emission.value.toFixed()} tCO2e`),
      },
    }));
});

const scope1ChartOptions: ChartOptions = {
  plugins: {
    title: {
      display: true,
      text: 'tCO2e',
      position: 'bottom',
    },
  },
};

watch(dateRange, () => {
  refetch({
    isExternal: isExternal.value,
    adminMode: adminMode.value,
    ...(isExternal.value ? {
      shareToken: queryString.get('shareToken') ?? '',
      sharePassword: window.localStorage.getItem('sharePassword') ?? '',
      entityId: queryString.get('entity') ?? '',
    } : {}),
    ...formatForBE(),
  });
});

</script>

<template>
  <div v-if="isExternal" class="flex gap-5">
    <img
      class="h-10"
      :src="logoSquare"
      alt="Codio Impact Logo"
    >

    <p v-if="reportingProgress.length">
      <span class="font-semibold">Codio Impact</span><br />
      <template v-if="reportingProgress[0].entity.entitySettings.companyName">
        {{ reportingProgress[0].entity.entitySettings.companyName }}'s {{ t('Dashboard') }}
      </template>
    </p>
  </div>

  <div class="bg-white !px-6 pt-5">
    <div class="grid grid-cols-2 items-center pb-6">
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{ t('Overview') }}
      </h1>

      <DashboardShareModal v-if="!isExternal" v-slot="{ showModal }">
        <AtButton
          class="ml-auto"
          @click="showModal"
        >
          {{ t('Share') }}
        </AtButton>
      </DashboardShareModal>
    </div>
    <Statistics class="mb-5" :isExternal="isExternal" :items="statisticsItems" />
    <div class="flex justify-between mb-2">
      <h2 class="mb-5 font-medium">
        {{ t('Key statistics') }}
      </h2>
      <OgDateRangeInput
        v-model="dateRange"
        v-model:secondaryModel="chartDateRange"
      />
    </div>

    <div class="grid grid-cols-2 gap-3 mb-6">
      <div class="bg-gray-50 rounded-md">
        <h2 class="p-4 pb-0 font-medium">
          {{ t('Reporting Progress') }}
        </h2>
        <p class="text-xs pl-4 mt-0.5">
          {{ t('Click on the legend to hide categories') }}
        </p>

        <div class="grid w-full">
          <div class="w-full justify-self-center 2xl:w-1/2">
            <OgChart
              :chartData="reportingProgressData"
              :chartOptions="chartOptions"
              type="pie"
            />
          </div>
        </div>
      </div>
      <div class="bg-gray-50 rounded-md">
        <h2 class="p-4 pb-0 font-medium">
          {{ t('Scope Emissions') }}
        </h2>

        <OgChart
          :chartData="emissionChartData"
          :chartOptions="emissionsChartOptions"
          type="bar"
        />
      </div>
      <template v-for="scopeChart in scopesChartData" :key="scopeChart.label">
        <div class="bg-gray-50 rounded-md">
          <h2 class="p-4 pb-0 font-medium">
            {{ scopeChart.label }}
          </h2>

          <div class="grid w-full">
            <div class="w-full justify-self-center 2xl:w-2/3">
              <OgChart
                :chartData="scopeChart.chartData"
                :chartOptions="scope1ChartOptions"
                type="pie"
              />
            </div>
          </div>
        </div>
      </template>
      <OgChart
        v-for="({ dataPointTypeName, title, ...chartProps }) in charts"
        :key="dataPointTypeName"
        v-bind="chartProps"
        :title="title"
      />
      <AtAddButtonVue
        @click="() => { isModalRevealed = true }"
      />
      <MlModal
        class="m-6 min-w-[24rem] max-w-7xl w-full h-full"
        :isRevealed="isModalRevealed"
        @close="() => { isModalRevealed = false }"
      >
        <div class="px-6 flex flex-col">
          <h3 class="text-xl font-bold">
            {{ t('Add chart to dashboard') }}
          </h3>

          <p class="mt-3">
            {{ t('Select the topic and datapoint, then click on the ‘Star’ to add the chart to your dashboard.') }}
          </p>
          <hr class="my-4" />
          <div class="mt-4 border border-gray-100 p-4 rounded-md">
            <OgDataPointTypeSelect
              class="col-span-9"
              selectClassWrapper="!w-[244px]"
              autocompleteClassWrapper="!w-[374px]"
              :withExistingDataPoints="true"
              @update:modelValue="selectedDataPointTypeName = $event?.name || ''"
            />
          </div>
          <template
            v-if="selectedDataPointTypeName
              && tmChartSelectionLoading
            "
          >
            <div class="mt-4 pl-4">
              <span>Loading...</span>
            </div>
          </template>
          <template v-else>
            <template
              v-if="selectedDataPointTypeName
                && esgDataPointsLength
              "
            >
              <div class="bg-[rgba(156,163,175,0.05)] mt-6 mb-8">
                <OgChart
                  v-bind="chartData"
                  data-cy="OgChart"
                  :showStarIcon="true"
                  class="bg-gray-200/25 max-w-[520px]"
                />
              </div>
            </template>
            <OgDataTable
              v-if="selectedDataPointTypeName && chartSelectionDataPoints.length > 0"
              :items="groupDataPointsByProject({ dataPointsByType: chartSelectionDataPoints, items })"
              :headers="calculateHeaders({ items, isAdminMode: adminMode })"
              controlsHidden
              secondStyle
              :hasTotal="true"
            >
              <template
                v-for="item in items"
                :key="item.header.value"
                #[`item-${item.header.value}`]="dp"
              >
                <MlDataPointValue
                  v-if="dp[item.header.value]"
                  :dataPointValueAndType="dp[item.header.value]"
                />
              </template>
            </OgDataTable>
          </template>
        </div>
      </MlModal>
    </div>
  </div>
  <TmWorkspacePerformance
    :reportingProgress="reportingProgress"
    :numberOfWorkspaces="dto.numberOfEntities"
  />
</template>
